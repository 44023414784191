/********************************************************************************
 * メンテナンスモデル
 *
 * @since 2024/07/10
 * @author 松本裕介
 *
 *********************************************************************************/

//#region モジュールのインポート

  // フレームワークのインポート
  import gql from 'graphql-tag';

 //#endregion

//#region インターフェース

// メンテナンス
export interface iMaintenance {
  id: string;
  isMaintenance: boolean;
  FromDateTime: string;
  ToDateTime: string;
  Info: string;
}

//#endregion

//#region graphQLスキーマ

// 取得
  export const get = gql`
  query GetMaintenance($getMaintenanceId: ID!) {
    getMaintenance(id: $getMaintenanceId) {
      id
      isMaintenance
      FromDateTime
      ToDateTime
      Info
    }
  }`;

// 更新
  export const update = gql`
  mutation UpdateMaintenance($maintenanceInput: MaintenanceInput!) {
    updateMaintenance(maintenanceInput: $maintenanceInput) {
      Success
      Message
      Maintenance {
        id
        isMaintenance
        FromDateTime
        ToDateTime
        Info
      }
    }
  }`;

//#endregion
