/********************************************************************************
 * メインモジュール
 *
 * @since 2022/08/30
 * @author 松本裕介
 *
 *********************************************************************************/

//#region モジュールのインポート

// フレームワークのインポート
import { createApp, provide, h } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { key, store }from './store'

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"

import { DefaultApolloClient } from '@vue/apollo-composable';
import {ApolloClient, createHttpLink, InMemoryCache,} from '@apollo/client/core';

//#endregion

//#region Apolloクライアント設定

// ApolloサーバのURIを作成
const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_API,
});

// キャッシュ方法を設定
const cache = new InMemoryCache({addTypename: false});

// Apolloクライアントのインスタンスを作成
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});

//#endregion

//#region アプリ設定

// アプリのインスタンスを作成
const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
});

// アプリのエントリポイントをマウント
app.use(store, key).use(router).mount('#app');

//#endregion