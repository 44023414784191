/********************************************************************************
 * ルーター
 *
 * @since 2022/08/25
 * @author 松本裕介
 *
 *********************************************************************************/

//#region モジュールのインポート

// フレームワークのインポート
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// ※ルーターでは、useStore()ではなく、直接storeをインポートして使用する。
import { store } from "@/store" 

//#endregion

//#region ルート設定

// ルート設定
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: { requiresAuth: false },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/SignInView.vue')
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    meta: { requiresAuth: false },
    components: {
      header: () => import('../components/HeaderPublic.vue'),
      main: () => import('../views/MaintenanceView.vue')
    }
  },
  {
    path: '/signin',
    name: 'signin',
    meta: { requiresAuth: false },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/SignInView.vue')
    }
  },
  {
    path: '/competition',
    name: 'competition',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/CompetitionView.vue')
    }
  },
  {
    path: '/applysetting',
    name: 'applysetting',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/ApplySettingEditView.vue')
    }
  },
  {
    path: '/apply/:applyid',
    name: 'apply',
    meta: { requiresAuth: false },
    components: {
      header: () => import('../components/HeaderPublic.vue'),
      main: () => import('../views/ApplyView.vue')
    }
  },
  {
    path: '/resultsetting',
    name: 'resultsetting',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/ResultSettingEditView.vue')
    }
  },
  {
    path: '/result/:resultid',
    name: 'result',
    meta: { requiresAuth: false },
    components: {
      header: () => import('../components/HeaderPublic.vue'),
      main: () => import('../views/ResultView.vue')
    }
  },
  {
    path: '/competitionedit',
    name: 'competitionedit',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/CompetitionEditView.vue')
    }
  },
  {
    path: '/applyteam',
    name: 'applyteam',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/ApplyTeamView.vue')
    }
  },
  {
    path: '/applyteamedit',
    name: 'applyteamedit',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/ApplyTeamEditView.vue')
    }
  },
  {
    path: '/applyteampaymentedit',
    name: 'applyteampaymentedit',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/ApplyTeamPaymentEditView.vue')
    }
  },
  {
    path: '/applyplayer',
    name: 'applyplayer',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/ApplyPlayerView.vue')
    }
  },
  {
    path: '/applyplayeredit',
    name: 'applyplayeredit',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/ApplyPlayerEditView.vue')
    }
  },
  {
    path: '/competitionteam',
    name: 'competitionteam',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/CompetitionTeamView.vue')
    }
  },
  {
    path: '/competitionteamedit',
    name: 'competitionteamedit',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/CompetitionTeamEditView.vue')      
    }
  },
  {
    path: '/competitionteamselect',
    name: 'competitionteamselect',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/CompetitionTeamSelectView.vue')      
    }
  },
  {
    path: '/competitionplayer',
    name: 'competitionplayer',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/CompetitionPlayerView.vue')
    }
  },
  {
    path: '/competitionplayeredit',
    name: 'competitionplayeredit',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/CompetitionPlayerEditView.vue')
    }
  },
  {
    path: '/block',
    name: 'block',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/BlockView.vue')
    }
  },
  {
    path: '/blockedit',
    name: 'blockedit',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/BlockEditView.vue')
    }
  },
  {
    path: '/blockcopyedit',
    name: 'blockcopyedit',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/BlockCopyEditView.vue')
    }
  },
  {
    path: '/match/tournament',
    name: 'matchtournament',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/MatchTournamentView.vue')
    }
  },
  {
    path: '/match/league',
    name: 'matchleague',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/MatchLeagueView.vue')
    }
  },
  {
    path: '/match/individual',
    name: 'matchindividual',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/MatchIndividualView.vue')
    }
  },
  {
    path: '/match/individualedit',
    name: 'matchindividualedit',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/MatchIndividualEditView.vue')
    }
  },
  {
    path: '/register',
    name: 'register',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/RegisterView.vue')
    }
  },
  {
    path: '/resultcheck',
    name: 'resultcheck',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/ResultCheckView.vue')
    }
  },
  {
    path: '/organization',
    name: 'organization',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/OrganizationEditView.vue')
    }
  },
  {
    path: '/user',
    name: 'user',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/UserView.vue')
    }
  },
  {
    path: '/useredit',
    name: 'useredit',
    meta: { requiresAuth: true },
    props: true,
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/UserEditView.vue')
    }
  }
]

// ルーターインスタンスの作成
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// グローバルビフォーガードの登録
router.beforeEach((to, from, next) => {

  // 認証が必要なページで且つトークンがない場合
  if (to.matched.some((record) => record.meta.requiresAuth) && store.state.auth.Token === "") {

    // サインインページへ遷移
    next({ path: '/signin', query: { redirect: to.fullPath } });

  // 通常の場合
  }else{

    // 次ページへ遷移
    next();

  } 
  
});

//#endregion

export default router
